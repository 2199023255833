import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet'

import { UserContext } from '../../../context/UserContext';
import Admin from '../../dropdowns/Admin';

const View = () => {
  const { highestRole, notices, newNotices } = useContext(UserContext);
  const { user, logout, isAuthenticated } = useAuth0();

  return (
    <Fragment>
      <Helmet>
        <style>{` body { padding-top:4.3rem; } `}</style>
      </Helmet>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" aria-current="page" to="/"><span className="text-secondary">SFO</span> Wayfinding Guidance System</Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              { isAuthenticated && (
                <Fragment>
                  { notices.filter(x => x.status==='A').length > 0 && (
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/notifications">
                        Notifications
                        { newNotices > 0 && <span className="ms-1 badge rounded-pill text-bg-danger">{newNotices}</span>}
                      </Link>
                    </li>  
                  )}
                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/log-ticket">Log a Ticket</Link>
                  </li>
                  { highestRole !== 'viewer' && (
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/wgs">View Guidance</Link>
                    </li>
                  )}
                  { highestRole !== 'viewer' && (
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/assets">Review Assets</Link>
                    </li>
                  )}
                  <li className="nav-item dropdown ms-2">
                    <span className="nav-link dropdown-toggle pointer" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt={user?.name} src={user?.picture} className="avatar" />
                    </span>
                    <ul className="dropdown-menu dropdown-menu-md-end" aria-labelledby="navbarDropdown">
                      <Admin />
                      <li className="pointer dropdown-item" onClick={() => {
                        logout({ returnTo: window.location.origin })
                      }}>Logout</li>
                      <li><hr className="dropdown-divider" /></li>
                      <li><p className="dropdown-item small mb-0 text-muted">Version 8.996</p></li> {/* don't forget to upload in taskHeader too!*/}
                    </ul>
                  </li>
                </Fragment>
              )}
              {/* not including login button here due to OIDC/email options */}
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default View;
