import React, { createContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios'

import useNetwork from "../hooks/useNetwork";
import db from '../utils/dexie-provider.js'

import HelpModal from '../components/_products/sfo/HelpModal';

// future:
// maybe show a yellow dot if they have bad connectivity?
// fix the since not showing on first load

// cache api: future expiratiion dates:
// https://gomakethings.com/how-to-set-an-expiration-date-for-items-in-a-service-worker-cache/

// cancel fetch:
// https://davidwalsh.name/cancel-fetch

// progress indicator (if you have zip issues):
// https://stackoverflow.com/questions/47285198/fetch-api-download-progress-indicator


// LKLKLK:
// on first load, check to see if there are any locally saved indexeddb things
// then will need to move the cache check to... it's own component

// lklklk future:
// consider moving the configs to eams-admin/system db so we can push updates via db


export const SiteContext  = createContext({});

export const SiteProvider = ({ hostname, children }) => {
  const { online } = useNetwork();
  const [config, setConfig] = useState({ title: '' });
  const [siteSchemas, setSiteSchemas] = useState([]);
  const [syncing, setSyncing] = useState(0);

  useEffect(() => {
    console.log(hostname);

    (async () => {
      try {
        let lastSync = await db.system.get('lastSiteSync');
        let resync = 60 * 60 * 1000 * 12; // 12 hours
        let needSync = (Date.now() - lastSync?.value) > resync ? true : false;

        // check if we haven't synced and/or lastSync > 2 hours
        if((!lastSync || needSync) && online) {
          const res = await axios.request({method: "GET", url: `/system/schemas`});
          if(!res.status === 200) alert('Error.'); // lklklk
          setSiteSchemas(res.data);

          await db.schemas.bulkPut(res.data);
          await db.system.put({ name: 'lastSiteSync', value: Date.now()});

        } else {
          let schemas = await db.schemas.toArray();
          setSiteSchemas(schemas);
        }

      } catch (error) {
        console.log(error);
        alert('Error.'); // lklklk
      }
    })();

    // lklklk for later:
    // https://stackoverflow.com/questions/46835825/conditional-css-in-create-react-app

    let config = { title: '' };

    // require(`../styles/_products/wayfinder.scss`);
    // require(`../styles/_products/expo.scss`);
    require(`../styles/_products/wayfindit.scss`);
    // require(`../styles/_products/sfo.scss`);
    // require(`../styles/_products/icp.scss`);

    if(hostname==='emodulex.wayfindit.com' || hostname==='emx.wayfindit.com') {
      config = {
        product: 'mapping',
        title: 'eModulex',
        supportEmail: { name: 'EMX Support', email: 'support@wayfindit.com' },
        adminIds: [1],
        // below are default schemaIds, ie for Wayfindit defaults
        schemaIds: {
          'plot-details': 25,
          'plot-message': 28
        },
        taskEmails: false,
        showQrCodes: false
      }
    } else if(hostname.includes('wayfindit') || hostname==='admin.mstdsqr.com') {
      config = {
        product: 'mapping',
        title: 'Wayfindit',
        supportEmail: { name: 'Wayfindit Support', email: 'support@wayfindit.com' },
        adminIds: [1],
        // below are default schemaIds, ie for Wayfindit defaults
        schemaIds: {
          'plot-details': 25,
          'plot-message': 28
        },
        terms: {
          project: ['site','sites','Site','Sites'],
          phase: ['phase/job','phases/jobs','Phase/Job','Phases/Jobs']
        },
        taskEmails: false,
        showQrCodes: true
      }
    } else if(hostname==='wgs.flysfo.io') {
      config = {
        product: 'mapping',
        gis: true,
        title: 'WGS',
        supportEmail: { name: 'Wayfinding @ SFO', email: 'wayfinding@flysfo.com' },
        adminIds: [2],
        taskEmails: true,
        selfRegister: true,
        libraries: [1,2]
      }
    } else if(hostname==='icp.jblapp.com' || hostname.includes('inspect')) {
      config = {
        product: 'mapping',
        title: 'ICP',
        supportEmail: { name: 'ICP Support', email: 'support@junebuglabs.com' },
        adminIds: [1],
        // below are default schemaIds, ie for Wayfindit defaults
        schemaIds: {
          'edit-plot': 111
        },
        terms: {
          project: ['site','sites','Site','Sites'],
          task: ['inspection','inspections','Inspection','Inspections']
        },
        taskEmails: false
      }
    }

    setConfig(config);

    if (typeof navigator.storage.estimate === 'function') {
        navigator.storage.estimate().then((estimate) => {
          let avail = (estimate.usage / estimate.quota * 100).toFixed(2);
          // console.log(estimate.usage);
          // console.log(estimate.quota);
          console.log(`storage: ${avail}%`);
        });
    } else {
      console.log('storage: estimate not available');
    }
  }, [hostname, online]);

  return (
    <SiteContext.Provider value={{ hostname, online, config, siteSchemas, syncing, setSyncing }}>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>

      {children}
      { !online && (
        <div className="position-fixed bg-danger" style={{
            bottom:16,
            right:16,
            zIndex:101,
            height:16,
            width:16,
            borderRadius:8
          }}>
        </div>
      )}

      { syncing > 0 && (
        <div className="position-fixed spinner-border spinner-border-sm text-dark" style={{bottom:16,right:16,zIndex:101}} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      { hostname==='wgs.flysfo.io' && <HelpModal /> }
    </SiteContext.Provider>
  );
};
