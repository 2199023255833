import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Generated  from '../../../Generated';
import DeleteBtn from '../../../../buttons/DeleteBtn';

import Modal from 'react-bootstrap/Modal';
import { nanoid } from 'nanoid';
import { formObj }  from '../../../../../utils/schemas';
import { Pencil } from 'react-bootstrap-icons';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    { id: '3', type: 'hidden', field: 'type' },
    // { id: '3', type: 'floating-textarea', field: 'textarea', label: 'Enter your question here' },
    // { id: '4', type: 'floating-input', field: 'textarea', label: 'Option A', placeholder: 'A' },
    // { id: '6', type: 'floating-input', field: 'textarea', label: 'Option B', placeholder: 'B' },
    // { id: '4', type: 'floating-input', field: 'textarea', label: 'Yes', placeholder: 'A' },
    // { id: '6', type: 'floating-input', field: 'textarea', label: 'No', placeholder: 'B' },
    { id: '4', type: 'article', field: 'content', placeholder: 'Add your content/notes/instructions here', hide: ['embed','quote','pre','image','layer']},
  ]},
]

const View = ({ colIdx, colName, obj, toParent }) => {
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const addNew = () => {
    if(!toParent) return;
    toParent({ type: 'add block', colIdx, colName, value: { id: nanoid(), type: 'html', content: '<p>Lorem ipsum amet sit dolor</p>' }})
  }

  const fromChild = () => {
    toParent({ type: 'delete block', colIdx, colName, value: obj?.id });
  }

  const onSubmit = async (data) => {
    if(!toParent) return;
    toParent({ type: 'save block', colIdx, colName, id: data.id, value: { content: data.content, conditional: data.conditional }})
    setShow(false);
  };

  if(!obj) {
    return <li onClick={addNew} className="dropdown-item pointer">Info/Instructions</li>
  }

  return (
    <Fragment>
      <button className="btn btn-sm mt-2 text-muted" onClick={() => setShow(true)}>
        <Pencil size={16} />
      </button>

      <Modal show={show} size="lg" onHide={handleClose} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit HTML</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id={obj ? `schema-html-${obj.id}` : `schema-html-${colIdx}`} schema={schema} size="md" pieces={{ register, control, setValue, watch, errors }} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <DeleteBtn toParent={fromChild} size="16" />
          <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-sm btn-outline-success" type="button" onClick={() => handleSubmit(onSubmit)()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
