import React, { Fragment, useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../context/SiteContext';
import { UserContext } from '../../context/UserContext';
import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';

import dayjs from 'dayjs'
import { formatHeaders, structureSort, typeSort, taskStructureSort } from '../../utils/reports';
import byStructure from '../../utils/reports/sorting/structure';
import getValues   from '../../utils/reports/values/values';

// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { config } = useContext(SiteContext);
  const { schemas } = useContext(UserContext);
  const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phase, types, typeVars, task, plotTasks, checklist, typeLists, libraries, symbols, plots, activeQtys } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  const [excels, setExcels] = useState([]);
  
  useEffect(() => {
    let filtered = schemas.filter(x => (x.type==='excel-report' || x.type==='excel-report-v2') && x.status==='A');
    setExcels(filtered);
  }, [schemas])

  const download = async (appId) => {
    console.clear();
    setLoading(true);
    let schema = schemas.find(x => x.appId===appId);
    let version = schema.type==='excel-report' ? 1 : 2;

    let messageSchema;
    if(config.schemaIds && 'plot-message' in config.schemaIds) {
      let found = schemas.find(x => x.id===config.schemaIds['plot-message']);
      messageSchema = found.schema;
    }

    let sheets = [...schema.schema];

    if(schema.type==='excel-report-v2') {
      for(const [idx, sheet] of sheets.entries()) {
        for(const [rowIdx, row] of sheet.rows.entries()) {
          let arr = ['new row placeholder'];
          if(row.loop==='plot') {
            arr = [...plots];

            if(sheet.typeIds)
              arr = arr.filter(x => sheet.typeIds?.includes(x.typeId));

            if(row.sort==='structure')
              arr = await byStructure({ planGroups, plans, plots: arr });
          }            

          let sources = row.columns.map(x => x.source);
          let values = await getValues({ sources, arr, project, phase, types, typeVars, symbols });
          sheets[idx].rows[rowIdx].data = values;
        }
      }

    } else {
      for (const [idx, report] of sheets.entries()) {
        sheets[idx].headers = await formatHeaders({ report, plots });
  
        let data = [];
        if(report?.type === 'types')
          [data] = await typeSort({ report, messageSchema, project, phase, types, typeVars, symbols, planGroups, plans, plots });
        else if(report?.type === 'quantities') {
          // lklklk: seems like we should be able to filter this the same way...
          // [data] = await structureSort({ report, messageSchema, project, phase, types, typeVars, symbols, planGroups, plans, plots: activeQtys });
          data = activeQtys.filter(x => x.typeId!=='total').map(x => ([x.code, x.description, x.quantity]));
        } else if(report?.type==='task summary')
          [data] = await taskStructureSort({ report, schemas, messageSchema, task, plotTasks, project, phase, types, typeVars, checklist, typeLists, libraries, symbols, planGroups, plans, plots }); 
        else
          [data] = await structureSort({ report, messageSchema, project, phase, types, typeVars, symbols, planGroups, plans, plots });
  
        sheets[idx].rowData = data;
      }
    }

    let timestamp = dayjs().format(schema.schema.dateFormat ? schema.schema.dateFormat : 'YYYY-MM-DD');
    let name = `${project.name} - ${phase.name} - ${schema.friendlyName} (XLSX)`;
    let filename = `${project.name} - ${phase.name} - ${schema.friendlyName} ${timestamp}`;

    if(project.name.includes('Enterprise Holdings'))
      filename = `Excel Report - ${project.number} - ${project.udf1}`;

    startWorker({ type: 'excel', pieces: { name, filename, types, version, content: sheets } });
    setLoading(false);
  }

  if(excels.length===0) return null;

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={() => download(excels[0].appId)} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Generating
          </Fragment>
        )}        
      </button>
      { excels.length > 1 && (
        <Fragment>
          <button type="button" className={`dropdown-toggle dropdown-toggle-split ${classes}`} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            { excels.map(obj => (
              <li key={obj.appId} className="dropdown-item pointer" onClick={() => download(obj.appId)}>{obj.friendlyName}</li>
            ))}

            {/* <li className="dropdown-item pointer">Text Only</li>
            <li className="dropdown-item pointer">Thumbnails</li>
            <li className="dropdown-item pointer">Install Status</li>
            <li className="dropdown-item pointer">Install Photos</li> */}
          </ul>
        </Fragment>
      )}
    </div>
  )
}

export default View;
