import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import { UserContext } from '../../../context/UserContext';
import { deepCopy, getDifference } from '../../../utils/functions';
import { useForm } from 'react-hook-form';
import FindType from './components/FindType';

// lklklk future:
// should just switch col, col1, col2 etc to array... DUH
// toggle to show error messages: https://stackoverflow.com/questions/69738454/react-hook-form-display-error-message-on-load
// add required / other fields/notes

const fieldMap = {
  plots: { udfs: 20, arrs:  5},
  plotTasks: { udfs: 100, arrs: 25 },
  tasks: { udfs: 50, arrs: 5 }
}

const View = ({ uid, arr, config, table, toParent }) => {
  const { schemas } = useContext(UserContext);
  const { control, register, formState: { errors }, watch, reset, setValue } = useForm();
  const [avail, setAvail] = useState({ udfs: [], arrs: [] });
  const [schema, setSchema] = useState();
  
  useEffect(() => {
    if(!config && (!arr || arr.length===0)) return;
    let arrCopy = deepCopy(arr);
    setSchema(arrCopy?.length > 0 ? arrCopy : [{ id: nanoid(), type: 'col-12', col: [] }]);
    return () => console.log('unmounting');
  }, [arr])

  const getFields = useCallback((arr) => {
    const fields = new Set();
    const processObj = (obj) => {
      ['col', 'col1', 'col2', 'col3', 'col4'].forEach(key => {
        if (key in obj) {
          getFields(obj[key]).forEach(field => {
            const match = field.match(/(udf\d+|arr\d+)/);
            if (match) fields.add(match[0]);
          });
        }
      });
      if ('field' in obj) {
        const match = obj.field.match(/(udf\d+|arr\d+)/);
        if (match) fields.add(match[0]);
      }
    };
  
    arr.forEach(processObj);
    return [...fields];
  }, []);

  useEffect(() => {
    if(!schema) return;
    if(!fieldMap[table]) return console.log('No mapped fields: ', table);
    let possibleUdfs = Array.from({length: fieldMap[table].udfs}, (_, i) => `udf${i + 1}`);
    let possibleArrs = Array.from({length: fieldMap[table].arrs}, (_, i) => `arr${i + 1}`);

    let used = getFields(schema);
    let usedUdfs = used.filter(x => x.includes('udf'));
    let usedArrs = used.filter(x => x.includes('arr'));

    if(table==='plots' || table==='plotTasks') {
      let found = schemas.filter(x => x.name==='plot-edit');
      // what do we do for ... projects like this...??? how can we protect udfs :(
      // found = findSchema(found, project);

      for(const plotEdit of found) {
        let plotFields = getFields(plotEdit.schema);
        let plotUdfs = plotFields.filter(x => x.includes('udf'));
        let plotArrs = plotFields.filter(x => x.includes('arr'));
        usedUdfs = [...new Set([...usedUdfs, ...plotUdfs])]
        usedArrs = [...new Set([...usedArrs, ...plotArrs])]
      }
    }

    usedUdfs.sort((a, b)=> (a.localeCompare(b, 'en', { numeric: true })));
    usedArrs.sort((a, b)=> (a.localeCompare(b, 'en', { numeric: true })));

    let availUdfs = getDifference(possibleUdfs, usedUdfs);
    let availArrs = getDifference(possibleArrs, usedArrs);

    setAvail({ udfs: availUdfs, arrs: availArrs });
    // console.clear();
    // console.log(schema);
    // console.log(table, availUdfs[0], availArrs[0]);
  }, [schemas, schema, table, getFields])

  const fromChild = useCallback(({ type, colIdx, colName, objIdx, direction, id, value }) => {
    // console.log(type, colIdx, colName, objIdx, direction, value);
    if(type==='add section') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if(objIdx!==undefined)
          arr.splice(colIdx, 0, value);
        else
          arr.push(value);

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });

    } else if(type==='add block') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if(objIdx!==undefined)
          arr[colIdx][colName].splice(objIdx, 0, value);
        else
          arr[colIdx][colName].push(value);

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    } else if(type==='delete section') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if (arr && arr[colIdx]) {
          const idx = arr.findIndex(x => x.id === value);
          if (idx > -1) arr.splice(idx, 1);
        }

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    } else if(type==='delete block') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if (arr && arr[colIdx] && arr[colIdx][colName]) {
          const idx = arr[colIdx][colName].findIndex(x => x.id === value);
          if (idx > -1) {
            arr[colIdx][colName].splice(idx, 1);
          }
        }

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    } else if(type==='save section') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if (arr && arr[colIdx]) {
          const idx = arr.findIndex(x => x.id === id);
          if (idx > -1) {
            let update = {...arr[idx], ...value};
            if(JSON.stringify(update.schema)==='{"appId":null}') delete update.schema;
            if(JSON.stringify(update.conditional)==='{"conditions":[],"type":"AND"}') delete update.conditional;
            arr[idx] = update;
          }
        }

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    } else if(type==='save block') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if (arr && arr[colIdx] && arr[colIdx][colName]) {
          const idx = arr[colIdx][colName].findIndex(x => x.id === id);
          if (idx > -1) {
            let update = {...arr[colIdx][colName][idx], ...value};
            if(JSON.stringify(update.schema)==='{"appId":null}') delete update.schema;
            if(JSON.stringify(update.conditional)==='{"conditions":[],"type":"AND"}') delete update.conditional;
            arr[colIdx][colName][idx] = update;
          }
        }

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    } else if(type==='move block') {
      setSchema(prev => {
        let arr = deepCopy(prev);
        if (arr && arr[colIdx] && arr[colIdx][colName]) {
          if (objIdx > -1) {
            // determine swap direction + validate boundaries
            const swapIdx = direction === 'up' ? objIdx - 1 : objIdx + 1;
            if (swapIdx >= 0 && swapIdx < arr[colIdx][colName].length) {
              [arr[colIdx][colName][objIdx], arr[colIdx][colName][swapIdx]] = [arr[colIdx][colName][swapIdx], arr[colIdx][colName][objIdx]];
            }
          }
        }

        if(toParent) toParent({ type: 'schema update', value: arr })
        return arr;
      });
    }
  }, [toParent]);

  const addSection = useCallback((section) => {
    setSchema(prev => prev.concat({ id: nanoid(), ...section }));
  }, []);

  const start = () => setSchema([{ id: nanoid(), type: 'col-12', col: [] }]);

  if(!schema && !config) return (
    <div>
      <p>This asset type is currently using default fields. If you would like to view or edit custom fields with our beta field editor, click below.</p>
      <button className="d-block mx-auto btn btn-outline-danger" onClick={start}>Use Beta Field Editor</button>
    </div>
  );

  return (
    <Fragment>
      { schema?.map((obj, colIdx) => <FindType key={obj.id} colIdx={colIdx} avail={avail} obj={obj} pieces={{ schemas, register, control, setValue, watch, errors }} toParent={fromChild} />)}
      <hr />
      <div className="row">
        <div className="col">
          <div className="btn-group">
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => addSection({ type: 'col-12', col: [] })}>Add New Section</button>
            <button type="button" className="btn btn-sm btn-outline-dark dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li onClick={() => addSection({ type: 'col-12', col: [] })} className="dropdown-item pointer">Single Column</li>
              <li onClick={() => addSection({ type: 'col-6', col1: [], col2: [] })} className="dropdown-item pointer">6 - 6</li>
              <li onClick={() => addSection({ type: 'col-48', col1: [], col2: [] })} className="dropdown-item pointer">4 - 8</li>
              {/* 336, 363, 4, 48, 84, 6, 633, 12 */}
            </ul>
          </div>
          <button className="btn btn-sm btn-outline-dark ms-1" onClick={reset}>Reset Preview</button>
        </div>
      </div>
    </Fragment>
  );
}

export default View;
