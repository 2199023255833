import React, { forwardRef, useEffect, useState } from 'react'

// future: do a conditional where we check for "Other" in loc3
// and if that shows up we show a text box in place of loc4

// future: need to make it possible for this to be required

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const value = pieces.watch(item.field);
  const [loc1, setLoc1] = useState();
  const [loc2, setLoc2] = useState();
  const [loc3, setLoc3] = useState();
  const [loc4, setLoc4] = useState();

  useEffect(() => {
    if(!value) return;
    let vals = value.split(' › ');
    for (var i = 0; i < vals.length; i++) {
      if(i===0) setLoc1(vals[i]);
      else if(i===1) setLoc2(vals[i]);
      else if(i===2) setLoc3(vals[i]);
      else if(i===3) setLoc4(vals[i]);
    }
  }, [value])

  useEffect(() => {
    if(!loc1) return;
    let arr = [];
    if(loc1) arr.push(loc1);
    if(loc2) arr.push(loc2);
    if(loc3) arr.push(loc3);
    if(loc4) arr.push(loc4);

    let list = arr.join(' › ');
    pieces.setValue(item.field, list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc1, loc2, loc3, loc4])


  return (
    <div className="row">
      <div className="col-sm-6">
        <label>Select issue location</label>
        <select
          value={loc1}
          className={`form-select mb-2 ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
          onChange={(e) => {
            e.target.blur();
            setLoc1(e.target.value);
            setLoc2('');
            setLoc3('');
            setLoc4('');
          }}>
          { loc1arr.map((opt, idx) => {
            if(!opt.label) opt.label = opt.value;
            return (
              <option key={`loc1arr-${idx}`} value={opt.value}>{opt.label}</option>
            )
          })}
        </select>

        { loc1 && (
          <select
            value={loc2}
            className={`form-select mb-2 ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
            onChange={(e) => {
              e.target.blur();
              setLoc2(e.target.value);
              setLoc3('');
              setLoc4('');
            }}>
            { loc2arr.filter(x => !x.parent || x.parent === loc1).map((opt, idx) => {
              if(!opt.label) opt.label = opt.value;
              return (
                <option key={`loc2arr-${idx}`} value={opt.value}>{opt.label}</option>
              )
            })}
          </select>
        )}

        { loc2 && (
          <select
            value={loc3}
            className={`form-select mb-2 ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
            onChange={(e) => {
              e.target.blur();
              setLoc3(e.target.value);
              setLoc4('');
            }}>
            { loc3arr.filter(x => x.label==='Select' || (x.loc1 === loc1 && x.loc2 === loc2)).map((opt, idx) => {
              if(!opt.label) opt.label = opt.value;
              return (
                <option key={`loc3arr-${idx}`} value={opt.value}>{opt.label}</option>
              )
            })}
          </select>
        )}

        {(loc3 && loc4arr.filter(x => x.loc1 === loc1 && x.loc2 === loc2 && x.loc3 === loc3).length > 0) && (
          <select
            value={loc4}
            className={`form-select mb-2 ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
            onChange={(e) => {
              e.target.blur();
              setLoc4(e.target.value);
            }}>
            { loc4arr.filter(x => x.label==='Select' || (x.loc1 === loc1 && x.loc2 === loc2 && x.loc3 === loc3)).map((opt, idx) => {
              if(!opt.label) opt.label = opt.value;
              return (
                <option key={`loc4arr-${idx}`} value={opt.value}>{opt.label}</option>
              )
            })}
          </select>
        )}
      </div>
    </div>
  )
});

const loc1arr = [
  { value: '', label: 'Select'},
  { value: 'Terminal - Landside' },
  { value: 'Terminal - Airside' },
  { value: 'Non-Terminal' },
]

const loc2arr = [
  { value: '', label: 'Select'},
  { value: 'Terminal 1', parent: 'Terminal - Landside' },
  { value: 'Terminal 2', parent: 'Terminal - Landside' },
  { value: 'Terminal 3', parent: 'Terminal - Landside' },
  { value: 'International Terminal', parent: 'Terminal - Landside' },
  { value: 'All Terminals', parent: 'Terminal - Landside' },
  { value: 'Terminal 1', parent: 'Terminal - Airside' },
  { value: 'Terminal 2', parent: 'Terminal - Airside' },
  { value: 'Terminal 3', parent: 'Terminal - Airside' },
  { value: 'International Terminal', parent: 'Terminal - Airside' },
  { value: 'All Terminals', parent: 'Terminal - Airside' },
  { value: 'AirTrain', parent: 'Non-Terminal' },
  { value: 'Garage', parent: 'Non-Terminal' },
  { value: 'Grand Hyatt at SFO', parent: 'Non-Terminal' },
  { value: 'Campus', parent: 'Non-Terminal' },
  { value: 'Rental Car Center', parent: 'Non-Terminal' },
  { value: 'Roadway', parent: 'Non-Terminal' },
]

const loc3arr = [
  { value: '', label: 'Select'},
  { value: 'Mezzanine', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Departures', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Arrivals', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Tunnel', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Courtyard', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Security Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Other', loc1: 'Terminal - Landside', loc2: 'Terminal 1'},
  { value: 'Mezzanine', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Departures', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Arrivals', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Tunnel', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Courtyard', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Security Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Other', loc1: 'Terminal - Landside', loc2: 'Terminal 2'},
  { value: 'Mezzanine', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Departures', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Arrivals', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Tunnel', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Courtyard', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Security Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Other', loc1: 'Terminal - Landside', loc2: 'Terminal 3'},
  { value: 'Mezzanine', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Departures', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Arrivals', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Tunnel', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Courtyard', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'ReCheck', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Security Checkpoint', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Offices', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Other', loc1: 'Terminal - Landside', loc2: 'International Terminal'},
  { value: 'Mezzanine', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Departures', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Arrivals', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Tunnel', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Courtyard', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Security Checkpoint', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Other', loc1: 'Terminal - Landside', loc2: 'All Terminals'},
  { value: 'Mezzanine', loc1: 'Terminal - Airside', loc2: 'Terminal 1'},
  { value: 'Departures', loc1: 'Terminal - Airside', loc2: 'Terminal 1'},
  { value: 'Mezzanine', loc1: 'Terminal - Airside', loc2: 'Terminal 2'},
  { value: 'Departures', loc1: 'Terminal - Airside', loc2: 'Terminal 2'},
  { value: 'Departures', loc1: 'Terminal - Airside', loc2: 'Terminal 3'},
  { value: 'Departures', loc1: 'Terminal - Airside', loc2: 'International Terminal'},
  { value: 'Arrivals', loc1: 'Terminal - Airside', loc2: 'International Terminal'},
  { value: 'Mezzanine', loc1: 'Terminal - Airside', loc2: 'All Terminals'},
  { value: 'Departures', loc1: 'Terminal - Airside', loc2: 'All Terminals'},
  { value: 'Garage A Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Garage G/BART Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Grand Hyatt at SFO Lower Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Grand Hyatt at SFO Upper Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'International Terminal A Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'International Terminal G Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Long-Term Parking Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Rental Car Center Lower Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Rental Car Center Upper Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 1 Lower Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 1 Upper Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 2 Lower Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 2 Upper Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 3 Lower Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Terminal 3 Upper Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'West Field to RCC Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'West Field to Terminals Platform', loc1: 'Non-Terminal', loc2: 'AirTrain'},
  { value: 'Long-Term Parking Garage 1', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Long-Term Parking Garage 2', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Long-Term Parking Garage Surface Lot', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Lot D', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core A/B', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core B', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core C', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core D', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core E', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core F', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Domestic Garage Core F/G', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'International Terminal Garage A', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'International Terminal Garage G', loc1: 'Non-Terminal', loc2: 'Garage'},
  { value: 'Airside Ops', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Building 575', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Building 674', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Building 682', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Building 710', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Ground Transportation Unit', loc1: 'Non-Terminal', loc2: 'Campus'},
  { value: 'Level 1', loc1: 'Non-Terminal', loc2: 'Rental Car Center'},
  { value: 'Level 2', loc1: 'Non-Terminal', loc2: 'Rental Car Center'},
  { value: 'Level 3', loc1: 'Non-Terminal', loc2: 'Rental Car Center'},
  { value: 'Level 4', loc1: 'Non-Terminal', loc2: 'Rental Car Center'},
]

const loc4arr = [
  { value: '', label: 'Select'},
  { value: 'Departures Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Oversized Baggage Drop-Off', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Arrivals Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Oversized Baggage Pick-Up', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Arrivals' },
  { value: 'Core A/B Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Tunnel' },
  { value: 'Core B Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Tunnel' },
  { value: 'Core C Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Tunnel' },
  { value: 'Courtyard 1', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Courtyard' },
  { value: 'Courtyard 2', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Courtyard' },
  { value: 'B Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Security Checkpoint' },
  { value: 'B Mezzanine Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 1', loc3: 'Security Checkpoint' },
  { value: 'Departures Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Oversized Baggage Drop-Off', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Departures' },

  { value: 'Arrivals Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Oversized Baggage Pick-Up', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Arrivals' },
  { value: 'Core D Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Tunnel' },
  { value: 'Courtyard 2', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Courtyard' },
  { value: 'Courtyard 3', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Courtyard' },
  { value: 'D Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 2', loc3: 'Security Checkpoint' },

  { value: 'Departures Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Oversized Baggage Drop-Off', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Departures' },

  { value: 'Arrivals Hall', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Oversized Baggage Pick-Up', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Arrivals' },

  { value: 'Core E Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Tunnel' },
  { value: 'Core F Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Tunnel' },
  { value: 'Core F/G Connector', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Tunnel' },

  { value: 'Courtyard 3', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Courtyard' },
  { value: 'Courtyard 4', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Courtyard' },
  { value: 'F1 Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Security Checkpoint' },
  { value: 'F3 Checkpoint', loc1: 'Terminal - Landside', loc2: 'Terminal 3', loc3: 'Security Checkpoint' },

  { value: 'Departures Hall', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Oversized Baggage Drop-Off', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'BART Threshold', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Departures' },

  { value: 'Arrivals Hall', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Re-check Hall', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Oversized Baggage Pick-Up', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Arrivals' },

  { value: 'Garage A Connector', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Tunnel' },
  { value: 'Garage G Connector', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Tunnel' },
  { value: 'Courtyard A', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Courtyard' },
  { value: 'Courtyard G', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Courtyard' },
  { value: 'A Checkpoint', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Security Checkpoint' },
  { value: 'G Checkpoint', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Security Checkpoint' },
  { value: 'Level 4 Offices', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Offices' },
  { value: 'Level 5 Offices', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Offices' },
  { value: 'Level 5 Administrative Offices', loc1: 'Terminal - Landside', loc2: 'International Terminal', loc3: 'Offices' },

  { value: 'Departures Hall', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Oversized Baggage Drop-Off', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Departures' },

  { value: 'Arrivals Hall', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Curbside', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Oversized Baggage Pick-Up', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Meet & Greet', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },
  { value: 'Center Island', loc1: 'Terminal - Landside', loc2: 'All Terminals', loc3: 'Arrivals' },

  { value: 'FIS Corridor', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Mezzanine' },
  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Recompose', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Gate', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },
  { value: 'Lounge/Club', loc1: 'Terminal - Airside', loc2: 'Terminal 1', loc3: 'Departures' },

  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Recompose', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Gate', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },
  { value: 'Lounge/Club', loc1: 'Terminal - Airside', loc2: 'Terminal 2', loc3: 'Departures' },

  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Recompose', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Gate', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },
  { value: 'Lounge/Club', loc1: 'Terminal - Airside', loc2: 'Terminal 3', loc3: 'Departures' },

  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Recompose', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Gate', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },
  { value: 'Lounge/Club', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Departures' },

  { value: 'Arrivals Hall', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'FIS', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Baggage Claim', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Baggage Carousel', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'International Terminal', loc3: 'Arrivals' },

  { value: 'Information Desk', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Exit Chute', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Walkway', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Recompose', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Concourse', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Gate', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Vertical Circulation', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
  { value: 'Lounge/Club', loc1: 'Terminal - Airside', loc2: 'All Terminals', loc3: 'Departures' },
]

export default Field;
