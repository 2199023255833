import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { useAxios }    from '../../../hooks/useAxios';
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';

// lklklk future:
// think about adding something to the generate config
// that is a conditional that lets you hide the dropdown
// if the values are empty? this would solve
// the issue of viewing the phase etc outside of the project...


import { formObj } from '../../../utils/schemas';
import { userArray, getDifference, titleCase, emptyObj } from '../../../utils/functions';
import { formatEmail } from '../../../utils/emails';
import dayjs from 'dayjs'

import { Discussion } from '../../system/discussion/Discussion';
import FileManager from '../../system/files/FileManager';
import ConvertLink from './ConvertLink';

import ApplicationReview from '../_products/sfo/ApplicationReview';

import Modal from 'react-bootstrap/Modal';
import Access      from '../../../components/system/access/Access';
import DownloadPdf from '../../../components/buttons/DownloadPdf';
import DownloadExcel from '../../../components/buttons/DownloadExcel';
import Generated   from '../../../components/system/Generated';
import DeleteBtn   from '../../../components/buttons/DeleteBtn';
// import EmailError from './EmailError';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const View = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { parentType, parent, obj, setObj, handleCancel, tasks, setTasks, phases } = props;
  const { hostname, config, siteSchemas } = useContext(SiteContext);
  const { userDetails, allUsers, schemas, projects, checklists } = useContext(UserContext);
  const pieces = props.pieces ? props.pieces : {};

  const [schema, setSchema] = useState([]);
  const [taskStatus, setTaskStatus] = useState([])
  const [taskTypes, setTaskTypes] = useState([])

  const [access, setAccess] = useState([]);
  const [accessUsers, setAccessUsers] = useState([]);
  const [accessTitle, setAccessTitle] = useState('Access');

  const [linked, setLinked] = useState([]);
  const [threads, setThreads] = useState([]);
  const [comments, setComments] = useState([]);

  const [checklist, setChecklist] = useState({});
  const [typeLists, setTypeLists] = useState([]);
  const [plotTasks, setPlotTasks] = useState([]);

  const [files, setFiles] = useState([]);
  const [filesLog, setFilesLog] = useState([]);

  const formText = { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };
  const errorCode = '234';

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  const objStatus = watch('status');

  useEffect(() => {
    if(emptyObj(obj)) return;
    (async function () {
      let res = await serverCall({ method: 'GET', url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
      if(res.data.threads) setThreads(res.data.threads);
      if(res.data.comments) setComments(res.data.comments);
      if(res.data.checklist) setChecklist(res.data.checklist);
      if(res.data.typeLists) setTypeLists(res.data.typeLists);

      // then get plotTask data
      let details = await serverCall({
        method: 'GET', url: `/system/tasks/survey/${obj.appId}`, eamsAuth0: true,
        headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
      });
      if(details.status!==200 || !details.data) return alert('Error getting task project/plots. Contact support.'); // lklklk
      setPlotTasks(details.data.plotTasks);
		})();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj])

  useEffect(() => {
    console.clear();
    if(emptyObj(obj)) return;

    // create open project link
    let parents = obj.parentIds.filter(x => x.type === 'project').map(x => x.id);
    let linked = projects.filter(x => parents.includes(x.id)).map(x => ({ appId: x.appId, name: x.name }));
    setLinked(linked);

    let found = schemas.find(x => x.name === 'edit-task');
    if(found) {
      if(parentType!=='project') {
        console.log(found.schema);
      }
      setSchema(found.schema);
    }

    let filled = formObj(found.schema, obj);
    reset(filled);

    found = schemas.find(x => x.name === 'task-status');
    if(found) setTaskStatus(found.schema);

    let configName = parentType==='project' ? 'task-types-project' : 'task-types';
    found = schemas.find(x => x.name === configName);
    if(found) setTaskTypes(found.schema);
  }, [parentType, schemas, projects, obj, reset])

  useEffect(() => {
    if(!access) return;
    let shareIds = parent?.access?.filter(x => x.type === 'user').map(x => x.id);
    let userIds  = access.filter(x => x.type === 'user').map(x => x.id);
    let groupIds = access.filter(x => x.type === 'group').map(x => x.id);
    let filtered = allUsers.filter(x => {
      let groups = x.groupArr.filter(x => groupIds.includes(x.id));
      return (shareIds?.includes(x.id) || userIds?.includes(x.id) || groups?.length > 0) ? true : false;
    })
    setAccessUsers(filtered);
  }, [access, allUsers, parent?.access])

  const fromChild = (data) => {
    const { type, value, ownerQty, groupQty, userQty } = data;

    if(type==='access') {
      // lklklk: need to update/warn about people who are assigned things but that do not have access to the task...?
      setAccess(value);

      if(ownerQty > 1 || groupQty > 0 || userQty > 0) {
        let qty = ownerQty + groupQty + userQty - 1;
        setAccessTitle(`Access (${qty})`);
      } else {
        setAccessTitle('Access');
      }

    } else if(type === 'file uploaded') {
      // we already have the taskId, we should be able to just push
    } else if(type === 'links updated') {
      setValue('parentIds',value);
    }
  }

  const onSubmit = async (data) => {
    let emailAssignees = data.emailAssignees;
    delete data.emailAssignees;

    data.updatedBy = userDetails.id;
    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedByUser = userDetails.byUser;

    // check if anyone has been newly assigned
    const newAssignees = getDifference(data.assignedTo, data.ogAssignedTo);
    let template = siteSchemas.find(x => x.type === 'email' && x.name === 'system-email');
    if(emailAssignees && newAssignees.length > 0 && template?.schema?.template) {
      let emails = [];
      let emailData = {...data};

      // task emails include user information,
      // for task edits let's just put whoever is assigning them in the email
      emailData.email = userDetails.email;
      emailData.firstName = userDetails.firstName;
      emailData.lastName = userDetails.lastName;

      let email = formatEmail({
        template: 'task assigned',
        html: template.schema.template,
        senderObj: config.supportEmail,
        recipientIds: newAssignees,
        subject: `${config.title}: ${emailData.name} Assigned`,
        emailData,
        logObj: { createdBy: userDetails.id }
      });
      emails.push(email)

      // only send task assignment email if site config allows
      if(config.taskEmails && emails.length > 0)
        data.eamsEmail = emails;
    }

    let res = await serverCall({ method: 'PATCH', data, url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
    // console.log(res);
    if(res.status===200) {
      // update managedByDisplay and new ogAssignedTo
      data.assignedToDisplay = userArray(allUsers, data.assignedTo);
      data.ogAssignedTo = data.assignedTo;

      // delete any email or file data since we don't need to store with the obj
      delete data.eamsEmail;
      delete data.eamsFiles;

      // lklklk if you are doing the manual publicStatus checks, do you need to update it here
      // in case the value has changed?

      // now update the main task objects
      let newTasks = [...tasks];
      let idx = newTasks.findIndex(x => x.id === data.id);
      newTasks[idx] = { ...newTasks[idx], ...data};
      setTasks(newTasks);

      // also check if response includes any new files...
      // if(response.files) {
      //
      // }

      // // check if response includes any log data
      // if(response.log) {
      //   let arr = [...logs];
      //   let idx = arr.findIndex(x => x.id === response.log.id);
      //   if(idx > -1)
      //     arr[idx] = response.log;
      //   else
      //     arr.push(response.log);
      //
      //   setLogs(arr);
      // }

      handleCancel();
    }
  };

  const toggleStatus = async ({ type }) => {
    // lklklk: consider moving this to the DeleteBtn component
    // then you can just pass the appropriate appId etc?
    let status = (type === 'activate') ? 'A' : 'D';

    let data = {
      id: obj.id,
      status: status,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedByUser: userDetails.byUser
    }

    let newTasks = [...tasks];
    let idx = newTasks.findIndex(x => x.id === data.id);
    newTasks[idx] = { ...newTasks[idx], ...data};
    setTasks(newTasks);

    let newObj = {...obj, ...data};
    let filled = formObj(schema, newObj);
    reset(filled);

    await serverCall({ method: 'PATCH', data, url: `/system/tasks/${obj.appId}`, eamsAuth0: true });

    if(type === 'delete confirmed')
      handleCancel();
  }

  const open = () => {
    navigate(`./${obj.appId}`);
  }

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit { obj.taskType === 'ticket' ? 'Ticket' : `${titleCase(obj.taskType)} Task` }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="request" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="request" title="Request">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Generated id="task-edit" schema={schema} size="md" pieces={{ vars: { 'task-types': taskTypes, 'task-status': taskStatus, 'access-users': accessUsers, 'all-users': allUsers, 'checklists': checklists, 'phases': phases }, register, control, setValue, watch, errors, toParent: fromChild }} />
            </form>
          </Tab>
          { obj.taskType==='Application' && (
            <Tab eventKey="review" title="Review">
              <ApplicationReview />
            </Tab>
          )}
          <Tab eventKey="access" title={accessTitle}>
            <Access type="task" url={`/system/tasks/${obj.appId}`} arr={obj.access} parent={obj} setParent={setObj} setParents={setTasks} toParent={fromChild} />
          </Tab>
          <Tab eventKey="discussion" title="Discussion">
            <Discussion parentType="task" parentId={obj.id} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
          </Tab>
          <Tab eventKey="files" title={ files.length > 0 ? `Attachments (${files.length})` : 'Attachments' }>
            <FileManager
              parentType="task"
              parentId={obj.appId}
              files={files}
              setFiles={setFiles}
              filesLog={filesLog}
              setFilesLog={setFilesLog}
            />
          </Tab>
          { hostname === 'wgs.flysfo.io' && (
            <Tab eventKey="convert-link" title="Convert/Link">
              <ConvertLink obj={obj} tasks={tasks} setTasks={setTasks} toParent={fromChild} />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        { params.projectId && (
          <Fragment>
            { obj.taskType === 'application' && <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Open Application</button>}
            { obj.taskType === 'fabrication' && <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Open</button>}
            { obj.taskType === 'survey'  && (
              <Fragment>
                { hostname === 'wgs.flysfo.io' && <button className="btn btn-sm btn-outline-dark float-end" onClick={() => navigate(`/survey`)}>Open Survey</button>}
                <DownloadPdf text="PDF" task={obj} plotTasks={plotTasks} project={parent} planGroups={pieces.planGroups} plans={pieces.plans} phasePlans={pieces.phasePlans} phases={phases} phase={pieces.activePhase} types={pieces.types} typeVars={pieces.typeVars} checklist={checklist} typeLists={typeLists} libraries={pieces.libraries} symbols={pieces.symbols} plots={pieces.activePlots} activeQtys={pieces.activeQtys} tasks={[obj]} photos={pieces.activePhotos} publicStatuses={pieces.publicStatuses} wrapper="shadow-sm me-1" classes="btn btn-sm btn-outline-primary" iconSize={14} />
                <DownloadExcel text="Excel" task={obj} plotTasks={plotTasks} project={parent} planGroups={pieces.planGroups} plans={pieces.plans} phasePlans={pieces.phasePlans} phases={phases} phase={pieces.activePhase} types={pieces.types} typeVars={pieces.typeVars} checklist={checklist} typeLists={typeLists} libraries={pieces.libraries} symbols={pieces.symbols} plots={pieces.activePlots} activeQtys={pieces.activeQtys} publicStatuses={pieces.publicStatuses} wrapper="shadow-sm me-1"  classes="btn btn-sm btn-outline-primary" />
                {/* <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Open Survey</button> */}
                {/* <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Share Survey</button> */}
              </Fragment>
            )}
          </Fragment>
        )}
        { objStatus === 'A' && (
          <DeleteBtn toParent={toggleStatus} text={`Archive Task`} wrapper="me-1" />
        )}
        { objStatus === 'D' && (
          <button type="button" className="d-inline-block btn btn-sm btn-outline-dark me-1" onClick={() => toggleStatus({type: 'activate'})}>
            Activate Task
          </button>
        )}

        { parentType!=='project' && linked.length === 1 && (
          <button type="button" className="d-inline-block btn btn-sm btn-outline-dark me-1" onClick={() => navigate(`/project/${linked[0].appId}`)}>
            Open Project
          </button>
        )}

        { parentType!=='project' && linked.length > 1 && (
          <div className="btn-group dropup">
            <button type="button" className="btn btn-sm btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Linked Projects
            </button>
            <ul className="dropdown-menu">
              { linked.map(obj => (
                <li key={obj.appId}><Link className="dropdown-item" to={`/project/${obj.appId}`}>{obj.name}</Link></li>
              ))}
            </ul>
          </div>
        )}

        <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleCancel}>
          {formText.cancel}
        </button>

        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default View;
